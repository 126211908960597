import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Value1 from "../../assets/images/ourvalue_1.png";
import Value2 from "../../assets/images/ourvalue_2.png";
import Value3 from "../../assets/images/ourvalue_3.png";

const Value = {
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
    1400: {
      margin: 60,
    },
  },
};

const valuesData = [
  {
    icon: Value1,
    title: "Accessibility",
    description:
      "Equal opportunities for all service providers to connect with customers.",
  },
  {
    icon: Value2,
    title: "Efficiency",
    description:
      "We strive to make the booking process quick and seamless for both users and service providers.",
  },
  {
    icon: Value3,
    title: "Innovation",
    description:
      "We are dedicated to staying ahead and enhancing our platform to meet user needs.",
  },
  {
    icon: Value1,
    title: "Customer Satisfaction",
    description:
      "We prioritize the satisfaction of our users by providing top-notch services and support.",
  },
];

function Ourvalue() {
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      // Update slidesPerView based on screen size
      if (window.innerWidth < 600) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 992) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set initial state
    handleResize();

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section className="our_value_section row_am">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="title_badge">Our values</span>
            <h2>
              <span>Our values</span> driven by relations
            </h2>
          </div>
          <div
            className="value_slider"
            data-aos="fade-in"
            data-aos-duration={1500}
          >
            <Swiper
              className="mySwiper"
              id="value_slider"
              {...Value}
              slidesPerView={slidesPerView}
              spaceBetween={15}
              modules={[Navigation]}
              navigation
            >
              {valuesData.map((value, index) => (
                <SwiperSlide key={index} className="item">
                  <div className="value_block">
                    <div className="icon">
                      <img src={value.icon} alt="image" />
                    </div>
                    <div className="text">
                      <h3>{value.title}</h3>
                      <p style={{ fontWeight: "bold" }}>{value.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ourvalue;
