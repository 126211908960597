import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Abtslider1 from "../../assets/images/abtslide_1.png";
import Abtslider2 from "../../assets/images/abtslide_2.png";
import Abtslider3 from "../../assets/images/abtslide_3.png";
import Abtslider4 from "../../assets/images/abtslide_4.png";
import Abtslider5 from "../../assets/images/abtslide_5.png";

const Slider = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const abtSlideImages = [
  Abtslider1,
  Abtslider2,
  Abtslider3,
  Abtslider4,
  Abtslider5,
  Abtslider4,
  Abtslider4,
  Abtslider5,
  Abtslider4,
  Abtslider4,
  Abtslider5,
  Abtslider4,
];

function About() {
  return (
    <>
      <section className="about_us_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="title_badge">About us</span>
            {/* <h2>
              Empowering Connections: <br />{" "}
              <span>Mission, Vision, and Interface</span>
            </h2>
            <p>
              Fuzzle simplifies service booking with a user-friendly app. Find
              cleaners, handymen, and more in seconds. <br />
              Compare prices, schedule, and enjoy hassle-free convenience, all
              from your phone.
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
