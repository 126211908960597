import React from 'react';
import { Link } from 'react-router-dom';
import Bluedots from '../../assets/images/blue_dotes.png'
import howstep1 from '../../assets/images/howstep1.png'
import howstep2 from '../../assets/images/howstep2.png'
import howstep3 from '../../assets/images/howstep3.png'

function HowWork() {
  return (

    <section className="how_it_section white_text">
      <div className="how_it_inner" data-aos="fade-in" data-aos-duration={1500}>
        <div className="dotes_blue">
          <img src={Bluedots} alt="image" />
        </div>
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
            <span className="title_badge">Quick &amp; easy</span>
            <h2>How it works in 3 steps</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="steps_block step_border" data-aos="fade-up" data-aos-duration={1500} >
                <div className="steps">
                  <div className="icon">
                    <img src={howstep1} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Download app</h3>
                    <ul className="social">
                      <li>
                        <Link to="#">
                          <i className="icofont-brand-android-robot" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="icofont-brand-apple" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="icofont-brand-windows" />
                        </Link>
                      </li>
                    </ul>
                    <p>
                      Download App. It will work for <br /> Android, Mac &amp;
                      Windows
                    </p>
                  </div>
                </div>
                <span className="step">01</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="steps_block step_border" data-aos="fade-up" data-aos-duration={1500}>
                <div className="steps">
                  <div className="icon">
                    <img src={howstep2} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Create account</h3>
                    <span className="tag_text">Free for 14 days</span>
                    <p>
                      Our app is FREE for 14 days, Sign up free <br /> for trial
                      period
                    </p>
                  </div>
                </div>
                <span className="step">02</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="steps_block" data-aos="fade-up" data-aos-duration={1500} >
                <div className="steps">
                  <div className="icon">
                    <img src={howstep3} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Enjoy the app</h3>
                    <span className="tag_text">Read FAQs for any query</span>
                    <p>
                      Enjoy our app &amp; share <br /> most amazing app experience
                    </p>
                  </div>
                </div>
                <span className="step">03</span>
              </div>
            </div>
            
          </div>
          <div className="text-center">
            <div className="btn_block">
              <Link to="/contact" className="btn puprple_btn ml-0">
                Get started now
              </Link>
              <div className="btn_bottom" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HowWork;
