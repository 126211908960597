import React from 'react'
import { Link } from 'react-router-dom'
import Review1 from '../../assets/images/review1.png'
import Review2 from '../../assets/images/review2..png'
import Review3 from '../../assets/images/review3..png'

function Reviewblock() {
    return (
        <>
            <section className="review_section row_am page_ban">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Reviews</span>
                        <h2>
                            Words from <span>trusted peoples</span>
                        </h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing indus orem Ipsum <br />{" "}
                            has been the industrys standard dummy text ever since.
                        </p>
                        <div className="google_rating">
                            <div className="star">
                                <span>
                                    <i className="icofont-star" />
                                </span>
                                <span>
                                    <i className="icofont-star" />
                                </span>
                                <span>
                                    <i className="icofont-star" />
                                </span>
                                <span>
                                    <i className="icofont-star" />
                                </span>
                                <span>
                                    <i className="icofont-star" />
                                </span>
                            </div>
                            <p>
                                4.9 / 5.0 Rated on App store |{" "}
                                <Link to="#" className="rate_link">
                                    {" "}
                                    1399 Total user reviews <i className="icofont-arrow-right" />{" "}
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="positive_inner in_page">
                        <div className="row">
                            <div className="review_side innerpage_block">
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review1} alt="image" />
                                            <div className="text">
                                                <h3>Willium Joy</h3>
                                                <span>Smartbrain Tech</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Neque, totam.
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review2} alt="image" />
                                            <div className="text">
                                                <h3>John Due</h3>
                                                <span>Corporate Agency</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Pariatur et, nemo distinctio eum omnis quam!
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review3} alt="image" />
                                            <div className="text">
                                                <h3>Maria</h3>
                                                <span>Company Inc</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Pariatur et, nemo distinctio eum omnis quam!
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review1} alt="image" />
                                            <div className="text">
                                                <h3>Willium Joy</h3>
                                                <span>Smartbrain Tech</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Neque, totam.
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review1} alt="image" />
                                            <div className="text">
                                                <h3>Willium Joy</h3>
                                                <span>Smartbrain Tech</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Neque, totam.
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review2} alt="image" />
                                            <div className="text">
                                                <h3>John Due</h3>
                                                <span>Corporate Agency</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Pariatur et, nemo distinctio eum omnis quam!
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review3} alt="image" />
                                            <div className="text">
                                                <h3>Maria</h3>
                                                <span>Company Inc</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Pariatur et, nemo distinctio eum omnis quam!
                                    </p>
                                </div>
                                <div className="review_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="coustomer_info">
                                        <div className="avtar">
                                            <img src={Review1} alt="image" />
                                            <div className="text">
                                                <h3>Willium Joy</h3>
                                                <span>Smartbrain Tech</span>
                                            </div>
                                        </div>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
                                        culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum
                                        nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Neque, totam.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Reviewblock