import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const Textslider = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const textItems = [
  "Effortless Booking",
  "User friendly",
  "Modern layout",
  "Diverse Access",
  "Live Chat",
];

function Textlist() {
  const [slidesPerView, setSlidesPerView] = useState(2.1);

  useEffect(() => {
    const handleResize = () => {
      // Update slidesPerView based on screen size
      if (window.innerWidth < 576) {
        setSlidesPerView(0.6);
      } else if (window.innerWidth < 991) {
        setSlidesPerView(1.1);
      } else if (window.innerWidth > 992) {
        setSlidesPerView(2.1);
      } else {
        setSlidesPerView(2.1);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set initial state
    handleResize();

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="text_list_section row_am"
        data-aos="fade-in"
        data-aos-duration={1500}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="container">
          <div className="container text-center">
            <span className="title_badge down_fix">Why choose our app</span>
          </div>

        

          <ul className={`points-list`}>
            {textItems.map((item, index) => (
              <li className="point-line" key={index}>
                <div className="points">
                  <span>
                    <i className="icofont-ui-check" />
                  </span>
                </div>
                <div className="text">
                  <h3>{item}</h3>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Textlist;
