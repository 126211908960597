import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo/fuzzle.png'
import Google from '../../assets/images/googleplay.png'
import Appstore from '../../assets/images/appstorebtn.png'

function Main() {
    return (
        <>
            <footer className="white_text" data-aos="fade-in" data-aos-duration={1500}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="logo_side">
                                <div className="logo">
                                    <Link to="/">
                                        <img style={{ width: "120px" }} src={Logo} alt="Logo" />
                                    </Link>
                                </div>
                                <div className="news_letter">
                                    <h3>Subscribe newsletter</h3>
                                    <p>Be the first to recieve all latest post in your inbox</p>
                                    <form>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Enter your email" />
                                            <button className="btn">
                                                <i className="icofont-paper-plane" />
                                            </button>
                                        </div>
                                        <p className="note">
                                            By clicking send link you agree to receive message.
                                        </p>
                                    </form>
                                </div>
                                <ul className="contact_info">
                                    <li>
                                        <Link to="mailto:Contact@fuzzle.app">Contact@fuzzle.app</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="tel: ">+1-900-123 4567</Link>
                                    </li> */}
                                </ul>
                                <ul className="social_media">
                                    <li>
                                        <Link to="/">
                                            <i className="icofont-facebook" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icofont-twitter" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icofont-instagram" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icofont-pinterest" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="download_side">
                                {/* <h3>Download app</h3> */}
                                <ul className="app_btn">
                                    <li>
                                        <Link to="#">
                                            <img className="blue_img" src={Google} alt="image" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <img className="blue_img" src={Appstore} alt="image" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="ft_inner">
                            <div className="copy_text">
                                <p> Crafted by @Fuzzle</p>
                            </div>
                            <ul className="links">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About us</Link>
                                </li>

                                <li>
                                    <Link to="/contact">Contact us</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main