import React, { useEffect } from 'react'
import Header from '../Header/Main'
import Banner from '../Menhome/Banner'
import Task from '../Menhome/Task'
import About from '../Menhome/About'
import Textlist from '../Menhome/Textlist'
import Service from '../Menhome/Service'
import HowWork from '../Menhome/HowWork'
import Review from '../Menhome/Review'
import Clients from '../Menhome/Clients'
import Pricing from '../Menhome/Pricing'
import Interface from '../Menhome/Interface'
import Text from '../Menhome/Text'
import Blog from '../Menhome/Blog'
import App from '../Menabout/App'
import Footer from '../Footer/Main'
import Aos from 'aos'

function Main() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <>
            <Header />
            <Banner />
            {/* <Task /> */}
            <div className='page_wrapper'>
                {/* <About /> */}
                
                {/* <Service /> */}
                {/* <HowWork /> */}
            </div>
            {/* <Review /> */}
            <div className='page_wrapper'>
                {/* <Clients />
                <Pricing /> */}
                <Interface />
                {/* <Text /> */}
                {/* <Blog /> */}
                <Textlist />
                <App/>
                <Footer />
            </div>
        </>
    )
}

export default Main