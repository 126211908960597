import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Main'
import About from '../components/About/Main'
import BlogList from '../components/Pages/BlogList/Main'
import BlogDetails from '../components/Pages/BlogDetails/Main'
import SignIn from '../components/Pages/Sign In/Main'
import SignUp from '../components/Pages/Sign Up/Main'
import Pricing from '../components/Pricing/Main'
import Reviews from '../components/Reviews/Main'
import Contact from '../components/Contact/Main'

const Routing = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-detail" element={<BlogDetails />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/bloglist" element={<BlogList />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  )
}

export default Routing