import React, { useState, useEffect } from "react";
import Banner10 from "../../assets/screenshot/home/home.png"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from 'swiper/modules';
import "swiper/css/pagination";
import "swiper/css/navigation";


const Slidernew = {
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};


function Banner() {
  const [ytban, setytban] = useState(false);


  return (
    <div className="banner-container">
      <section className="banner_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration={1500} >
              <div className="banner_text">
                {/* <div className="type-wrap">
                  <TypeAnimation id="typed" sequence={["Add any Text like here.", 500, "Type Writing Text..", 500, "Auto Type Text.", 500,]}
                    style={{ whiteSpace: "pre" }} repeat={Infinity} />
                </div> */}
                <h1>
                  Improve the way you book <span>on-demand services</span>
                </h1>
                <p>
                  Say hello to hassle-free on-demand services with Fuzzle.
                </p>
              </div>
              {/* <div className="used_app">
                <ul>
                  <li>
                    <img src={Banner1} alt="image" />
                  </li>
                  <li>
                    <img src={Banner2} alt="image" />
                  </li>
                  <li>
                    <img src={Banner3} alt="image" />
                  </li>
                  <li>
                    <Link to="#"
                      className="popup-youtube play-button"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                      data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" onClick={() => setytban(true)} >
                      <img src={Banner4} alt="img" />
                    </Link>
                  </li>
                </ul>
                <h3>12M + Active users</h3>
                <p>
                  The best application to manage your <br /> customer worldwide
                </p>
              </div> */}
              {/* <ul className="app_btn">
                <li>
                  <Link to="#">
                    <img className="blue_img" src={Banner5} alt="image" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img className="blue_img" src={Banner6} alt="image" />
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="banner_slider">
                {/* <div className="left_icon">
                  <img src={Banner7} alt="image" />
                </div>
                <div className="right_icon">
                  <img src={Banner8} alt="image" />
                </div> */}
                <Swiper id="frmae_slider" className="mySwiper" slidesPerView={1} autoplay={true} pagination={true} modules={[Pagination]} {...Slidernew} >
                  <SwiperSlide className="item">
                    <div className="slider_img">
                      <img src={Banner10} alt="image" />
                    </div>
                  </SwiperSlide>

                </Swiper>

              </div>
            </div>
          </div>
          {/* <div className="spinBlock" data-aos="fade-up" data-aos-duration={1500} >
            <span className="star">
              <img src={Banner7} alt="image" />
            </span>
            <div className="spin_box" id="scrollButton">
              <span className="downsign">
                <i className="icofont-long-arrow-down" />
              </span>
              <div className="spin-text">
                <img src={Icon1} alt="image" />
              </div>
            </div>
            <span className="star">
              <img src={Banner7} alt="image" />
            </span>
          </div> */}
        </div>
      </section>
      <img className="blueShape" src="/assets/shapes/circle.png" />
      {/* <img className="blueShape" src="/assets/shapes/shapecircle2.png" /> */}
      {/* <img className="blueShape" src="/assets/shapes/circle.png" /> */}

      {ytban && (
        <div className="modal fade youtube-video show" id="myModal" tabIndex={-1} style={{ display: "block", paddingRight: 17 }} aria-modal="true" role="dialog" >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytban(false)}>
              <button id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytban(false)} >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Banner;