import React, { useState, useEffect } from "react";
import Bluedots from "../../assets/images/blue_dotes.png";

function Whyus() {
  const [counts, setCounts] = useState({
    Countries: 0,
    reviews: 1500,
    followers: 0,
    download: 0,
  });

  useEffect(() => {
    const updateCounter = () => {
      const targetCounts = {
        Countries: 150,
        reviews: 2300,
        followers: 8,
        download: 17,
      };

      const duration = 1500;
      const stepSize = 10;
      const steps = duration / stepSize;

      Object.keys(targetCounts).forEach((key) => {
        const targetCount = targetCounts[key];
        const currentCount = counts[key];
        const step = Math.ceil((targetCount - currentCount) / steps);

        if (step !== 0) {
          const interval = setInterval(() => {
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: prevCounts[key] + step,
            }));
          }, stepSize);

          setTimeout(() => {
            clearInterval(interval);
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: targetCount,
            }));
          }, duration);
        }
      });
    };

    updateCounter();
  }, []);

  return (
    <>
      <section
        className="why_choose row_am white_text"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div>
          {/* <div className="dotes_blue">
            <img src={Bluedots} alt="image" />
          </div> */}
          <div className="container">
            {/* <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge">Why choos us</span>
              <h2>Company statistics</h2>
            </div> */}
            <div className="company_statistics">
              <p
                style={{
                  textAlign: "justify",
                  fontWeight:"bold",
                  position: "relative",
                  zIndex: "2",
                  color: "black",
                }}
              >
                fuzzle is an innovative app that allows users to book on-demand
                services with ease. Whether you need cleaning services, a
                handyman, or an appliance repair, fuzzle has got you covered.
                Anyone can sign up to provide their services, giving
                individuals and small service providers equal access to a wide
                customer base. Our platform allows users to compare prices,
                choose a date and time that fits their schedule, and enjoy the
                convenience of booking services with just a few taps on their
                phone.
              </p>
            </div>
          </div>
        </div>

        <div className="about_us_section">
          <div className="container">
            <div
              className="row abt_text"
              data-aos="fade-up"
              style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}
              data-aos-duration={1500}
            >
              <div className="col-md-5" style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <h2>
                  What is <span>Our mission &amp; vision ?</span>
                </h2>
              </div>
              <div className="col-md-7" style={{ textAlign: "justify" }}>
                <p style={{ color: "black" ,  fontWeight:"bold"}}>
                  Our mission at fuzzle is to revolutionize the way people book
                  services by providing a platform that is convenient,
                  efficient, and accessible to all. We envision a world where
                  individuals and small service providers can thrive in the gig
                  economy, connecting with customers and growing their
                  businesses without the high costs of traditional advertising.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
