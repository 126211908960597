import React, { useRef, useState } from "react";
import Bluedots from "../../assets/images/blue_dotes.png";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { ToastNotify } from "../Toast/Toast";

function Contactform() {
  const form = useRef();
  const [emailValid, setEmailValid] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!emailValid) {
        ToastNotify("error", "Invalid email address!");
        return;
      }

    emailjs
      .sendForm("service_m8986wl", "template_wti5y6a", form.current, {
        publicKey: "w5o8qbs7TirPUCDnn",
      })
      .then(
        () => {
          ToastNotify("success", "Email sent successfully!");
          console.log("SUCCESS!");
        },
        (error) => {
            ToastNotify("error", "Failed to sent email!");
          console.log("FAILED...", error.text);
        }
      );
  };
  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailRegex.test(e.target.value));
  };
  return (
    <>
      <section
        className="contact_form white_text row_am"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div className="contact_inner">
          <div className="container">
            <div className="dotes_blue">
              <img src={Bluedots} alt="image" />
            </div>
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-delay={100}
            >
              <span className="title_badge">Message us</span>
              <h2>Contact Form</h2>
              <p>
                Fill up form below, or email us at Contact@fuzzle.app. We'll get
                back to you asap.
              </p>
            </div>
            <form
              ref={form}
              onSubmit={sendEmail}
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      name="from_name"
                      type="text"
                      className="form-control"
                      placeholder="Name *"
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      name="from_email"
                      type="email"
                      className="form-control"
                      placeholder="Email *"
                      onChange={handleEmailChange}
                      required=""
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div
                  className="col-md-4"
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  <div className="btn_block">
                    <button type="submit" className="btn puprple_btn ml-0">
                      Submit
                    </button>
                    <div className="btn_bottom" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactform;
