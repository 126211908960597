import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import Interscrn2 from "../../assets/screenshot/others/1.png";
import Interscrn4 from "../../assets/screenshot/others/2.png";
import Interscrn3 from "../../assets/screenshot/others/3.png";
import screen from "../../assets/screenshot/others/screen2.jpg"
// import Interscrn4 from "../../assets/screenshot/others/4.png";
import Interscrn1 from "../../assets/screenshot/others/5.png";

const interfaceslider = {
  // loop: true,
  effect: "coverflow",
  margin: 20,
  centeredSlides: true,
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 4,
    slideShadows: false,
  },

  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

const interfaceImages = [
  {
    image: Interscrn1,
  },
  {
    image: screen,
  },
  {
    image: Interscrn3,
  },
  {
    image: Interscrn4,
  },
];

function Interface() {
  const [slidesPerView, setSlidesPerView] = useState(4);
  const swiper = useSwiper();

  useEffect(() => {
    const handleResize = () => {
      // Update slidesPerView based on screen size
      if (window.innerWidth < 600) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 800) {
        setSlidesPerView(2);
      } else if (window.innerWidth < 1080) {
        setSlidesPerView(3);
      } else if (window.innerWidth < 1400) {
        setSlidesPerView(4);
      } else {
        setSlidesPerView(4);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set initial state
    handleResize();

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <section className="row_am interface_section">
        <div className="container-fluid">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-delay={300}
          >
            <span className="title_badge">App screens</span>
            <h2>
              User-friendly <span>Design</span>
            </h2>
          </div>
          <div
            className="screen_slider"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <Swiper
              id="screen_slider"
              className=""
              {...interfaceslider}
              spaceBetween={10}
              allowTouchMove={true}
              pagination={true}
              modules={[Navigation, Pagination]}
              navigation
              slidesPerView={slidesPerView}
              autoplay={false}
            >
              {interfaceImages.map((value, index) => (
                <SwiperSlide key={index} className="item">
                  <div className="screen_frame_img">
                    <img src={value.image} alt="image1" />
                  </div>
                </SwiperSlide>
              ))}

            </Swiper>

          </div>
        </div>
      </section>
    </>
  );
}

export default Interface;
